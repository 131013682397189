import React from "react";
import { string } from "prop-types";
import Image from "./ImageQuerys/RandomImages";
import CtaSecondary from "./Buttons/secondary-cta";

const ExecutiveBio = (props) => {
  const { image, name, role, downloadable } = props;
  return (
    <figure className="w-full p-4 rounded-md mb-[64px]">
      <Image
        imageName={image}
        className="rounded-md max-w-[390px] md:min-h-[585px] max-h-[585px]"
      />
      <p className="font-bold text-[24px] mt-6 mb-0">{name}</p>
      <p className="font-bold text-[16px] mt-0 mb-2">{role}</p>
      <CtaSecondary
        download={downloadable}
        target={`/${downloadable}`}
        ctaTitle="Download"
      />
    </figure>
  );
};
ExecutiveBio.propTypes = {
  image: string,
  name: string,
  role: string,
  downloadable: string,
};

ExecutiveBio.defaultProps = {
  image: "",
  name: "",
  role: "",
  downloadable: "",
};

export default ExecutiveBio;
