import React from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Image from "../../components/ImageQuerys/HeroImages";

import ogImage from "../../images/global_assets/og-image.png";
import HeadingBuilder from "../../components-v2/Base/HeadingBuilder";
import NewMediaStyles from "./new-media-styles";
import chevron from "../../images/svg/chevron_left.svg";
import ExecutiveBio from "../../components/ExecutiveBios";

const headshots = [
  {
    name: "Matt Hyman",
    image: "Matt-Hyman.jpg",
    dl: "Matt-Hyman.7z",
    role: "Co-Founder and Co-CEO",
  },
  {
    name: "Zach Hyman",
    image: "Zach-Hyman.png",
    dl: "Zach-Hyman.7z",
    role: "Co-Founder and Co-CEO",
  },
  {
    name: "Doron Friedman",
    image: "Doron-Friedman.jpg",
    dl: "Doron-Friedman.7z",
    role: "Chief Product Officer",
  },
  {
    name: "RJ Horsley",
    image: "RJ-Horsley.jpg",
    dl: "RJ-Horsley.7z",
    role: "Chief Operating Officer",
  },
  {
    name: "Lisa Banks",
    image: "Lisa-Banks.jpg",
    dl: "Lisa-Banks.7z",
    role: "Chief Financial Officer",
  },
  {
    name: "Kevin Bryla",
    image: "Kevin-Bryla.jpg",
    dl: "Kevin-Bryla.7z",
    role: "Chief Marketing Officer",
  },
  {
    name: "JiNa Han",
    image: "JiNa-Han.png",
    dl: "JiNa-Han.7z",
    role: "Chief People Officer",
  },
  {
    name: "Bryan Solar",
    image: "Bryan-Solar.jpg",
    dl: "Bryan-Solar.7z",
    role: "Chief Product Officer",
  },
  {
    name: "Mark Brodahl",
    image: "Mark-Brodahl.jpg",
    dl: "Mark-Brodahl.7z",
    role: "Chief Revenue Officer",
  },
];

const ExecutiveBios = () => {
  return (
    <Layout>
      <SEO
        title="SpotOn Media"
        description="Find out the lastest news about SpotOn and contact us for press inqueries"
        image={`https://spoton.com/${ogImage}`}
      />
      <section className="new-media z-0 text-center">
        <HeadingBuilder
          level={1}
          injectionType={1}
          withBlueSymbol
          symbol="."
          className="new-media-title z-20 whitespace-pre mb-0"
        >
          {`Executive bios & \n headshots`}
        </HeadingBuilder>

        <Image
          className="absolute force-abs right-0 top-[-75px] lg:top-[-50px] w-[125px] lg:w-[175px]"
          imageName="custom-circle.png"
        />
        <Image
          className="new-media-shadow z-[-10]"
          imageName="fake-shadow.png"
        />
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 max-w-[1440px] translate-y-[-80px] px-[16px] lg:px-[80px] xl:px-[160px]">
        {headshots.map((image) => (
          <ExecutiveBio
            image={image.image}
            downloadable={image.dl}
            name={image.name}
            role={image.role}
          />
        ))}
      </section>
      <NewMediaStyles />
    </Layout>
  );
};

export default ExecutiveBios;
